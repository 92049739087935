import React, { Suspense, FC } from "react";
import styled from "styled-components";
import TopNav from "../components/topNav";
import cur8Logo from "@assets/cur8/img/cur8-white.png";
import { InlineLoader } from "@components/Loading/InlineLoader";

const WelcomeScreen = React.lazy(
  () => import("../components/onboarding/welcome")
);

const ContentWrapper = styled.div`
  padding: 1em;
  margin-top: 32px;
  max-width: 752px;
  margin: auto;
`;

const SignupSuccessPage: FC = () => (
  <>
    <TopNav bgColor="BLACK" logo={cur8Logo} />
    <ContentWrapper>
      <Suspense fallback={<InlineLoader />}>
        <WelcomeScreen />
      </Suspense>
    </ContentWrapper>
  </>
);

export default SignupSuccessPage;
