import React from "react";
import styled from "styled-components";
import {
  MEDIA_QUERIES,
  theme,
  ColorType,
} from "@ifgengineering/component-library";

interface TopNavProps {
  bgColor: ColorType;
  logo: string;
}

const TopNavContainer = styled.div<{ bgColor: ColorType }>`
  position: relative;
  height: 72px;
  display: flex;
  background-color: ${({ bgColor }) => theme.colors[bgColor]};
  padding: 0 86px;
  align-items: center;
  gap: 74px;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    padding: 0 16px;
    gap: 24px;
  }
`;

const LogoContainer = styled.div`
  height: 22px;
  & > img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  width: 100%;
`;

export const TopNav: React.FC<TopNavProps> = ({ bgColor, logo, children }) => {
  return (
    <TopNavContainer bgColor={bgColor}>
      <LogoContainer>
        <img src={logo} alt="logo" />
      </LogoContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
    </TopNavContainer>
  );
};

export default TopNav;
